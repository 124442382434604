import React from 'react'

import profileImage from '../../styles/images/Ash.png'
import aboutMe from '../../lib/aboutMe'

function About() {
  const { title, bio, description, summary } = aboutMe
  return (
    <section id="about" className="hero has-background-white is-fullheight animate__animated animate__fadeIn">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered reversed-columns">
            <div className="column
              is-10-mobile is-offset-1-mobile
              is-10-tablet is-offset-1-tablet
              is-5-desktop is-offset-1-desktop
              is-5-widescreen is-offset-1-widescreen
              is-5-fullhd is-offset-1-fullhd
              animate__animated animate__bounceInLeft">
              <br />
              <h1 className="title is-2 is-family-primary has-text-weight-light has-text-primary has-text-left">
                {title}
              </h1>
              <h2 className="is-6 is-family-primary has-text-weight-light">
                {bio}
                <br />
                <br />
                {description}
                <br />
                <br />
                {summary}
              </h2>
              <br />
              <div className="buttons">
                <a className="button is-medium is-linkedin" href="https://www.linkedin.com/in/ashleynathansmith/">
                  <span className="icon-linkedin">
                    <i className="fab fa-linkedin fa-lg"></i>
                  </span>
                </a>
                <a className="button is-medium is-github" href="https://github.com/ashleynathansmith">
                  <span className="icon-github">
                    <i className="fab fa-github fa-lg"></i>
                  </span>
                </a>
              </div>
            </div>
            <div className="column
              is-10-mobile is-offset-1-mobile
              is-10-tablet is-offset-1-tablet
              is-4-desktop is-offset-1-desktop
              is-4-widescreen is-offset-1-widescreen
              is-4-fullhd is-offset-1-fullhd
              animate__animated animate__bounceInRight">
              <br />
              <br />
              <figure className="image is-256x256">
                <img id="profile-image" className="is-rounded" src={profileImage} alt="Ashley Smith" />
              </figure>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default About