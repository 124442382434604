import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Home from '../src/components/common/Home'
import Nav from '../src/components/common/Nav'
import About from '../src/components/common/About'
import Projects from './components/projects/Projects'
import ProjectShow from './components/projects/ProjectShow'

function App() {
  return (
    <BrowserRouter>
    <Nav />
    <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/projects/:id" component={ProjectShow} />
    <Route path="/about" component={About} />
    <Route path="/projects" component={Projects} />
    </Switch>
    </BrowserRouter>
  )
}

export default App;
