import React from 'react'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <main>
      <section className="home-page hero is-fullheight-with-navbar is-white">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column
              is-8-mobile is-offset-2-mobile">
                <h1 className="title is-1 has-text-centered is-family-primary has-text-weight-light">
                  Hello, I'm <span className="name">Ashley Smith</span>.</h1>
                <h2 className="subtitle is-family-secondary has-text-centered has-text-weight-light">I'm an aspiring full-stack web developer.</h2>
                <div className="has-text-centered">
                  <Link
                    to="/projects"
                    className="button is-medium is-primary is-inverted has-text-weight-light"><span>View my work</span>
                    <span className="icon"><i className="fas fa-arrow-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Home