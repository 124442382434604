import React from 'react'
import { useParams } from 'react-router-dom'

import projectData from '../../lib/projectData'

function ProjectShow() {
  const [projects, setProjects] = React.useState(null)
  const { id } = useParams()

  React.useEffect(() => {
    const getData = () => {
      const data = projectData
      setProjects(data)
    }
    getData()
  }, [setProjects])

  return (
    <section className="hero is-fullheight-with-nav-bar">
      {projects && projects.map(project => (
        <section key={project.id}>
          {project.id === parseInt(id) &&
            <div className="hero is-fullheight 
            animate__animated 
            animate__fadeIn"
            >
              <div className="hero-body has-background-white">
                <div className="container animate__animated animate__fadeIn has-text-left"
                >
                  <div className="columns is-vcentered reversed-columns">
                    <div className="column is-5 is-offset-1
                  is-10-mobile is-offset-1-mobile
                  is-10-tablet is-offset-1-tablet
                  is-5-desktop is-offset-1-desktop
                  is-5-widescreen is-offset-1-widescreen
                  is-5-fullhd is-offset-1-fullhd"
                    >
                      <h1 className="title is-2 is-family-primary has-text-weight-light has-text-left">
                        {project.title}
                      </h1>
                      <p className="is-6 is-family-primary has-text-weight-light">
                        {project.description}</p>
                      <br />
                      <div className="container has-text-left logo-wrapper">
                        {projects && project.logos.map((logo, i) => (
                          <i
                            key={i}
                            id="icons"
                            className={logo}></i>
                        ))}
                        <div className="buttons">
                        <a className="source-button button mt-2 is-outlined is-dark is-mobile" href={project.sourceCode}>Source Code</a>
                        </div>
                      </div>
                    </div>
                    <div className="column is-4 is-offset-1">
                      <a href={project.url}>
                        <figure className="image is-4by3">
                          <img src={project.image} alt={project.name} />
                        </figure>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </section>
      ))
      }
    </section>
  )
}

export default ProjectShow