import pacnan from '../styles/images/pacnan.png'
import gorillaz from '../styles/images/gorillaz.png'
import news from '../styles/images/news-image-1.png'

const projectData = [{
  id: 1,
  title: 'JavaScript Game',
  url: 'https://ashleynathansmith.github.io/sei-project-1/',
  technologies: 'HTML, CSS, JavaScript',
  image: pacnan,
  description: 'My first project with General Assembly was to build a fully functioning game built in JavaScript. I decided to build pac-man which involved various aspects, with the most challenging being around the player and randomised computer movements. My aim was to keep it simple, including seperate files for HTML, CSS, JavaScript & using DRY principles when it came to JavaScript DOM manipulation.',
  logos: ['fab fa-html5 fa-3x m-2', 'fab fa-css3-alt fa-3x m-2', 'fab fa-js-square fa-3x m-2'],
  sourceCode: 'https://github.com/ashleynathansmith/sei-project-1',
},
{
  id: 2,
  title: 'Music Player App',
  url: 'https://determined-goldberg-24ccee.netlify.app/',
  technologies: 'React, JavaScript, HTML, CSS',
  image: gorillaz,
  description: 'Our second project gave us the freedom to connect to a public API including adding a React frontend for the first time. I gained a lot of experience in using React hooks as well as challenges accessing and fetching the correct data points needed through the API which was tested with Insomnia. Some of the technologies i used were Axios for fetching the API data, React frontend and bulma for front-end styling.',
  logos: ['fab fa-react fa-3x m-2', 'fab fa-js-square fa-3x m-2', 'fab fa-css3-alt fa-3x m-2','fab fa-html5 fa-3x m-2'],
  sourceCode: 'https://github.com/ashleynathansmith/sei-project-2',
},
{
  id: 3,
  title: 'News App',
  url: 'https://github.com/ashleynathansmith/sei-project-4',
  technologies: 'React, Python, JavaScript, HTML, CSS',
  image: news,
  description: `Our final project involved building a full-stack application using a Python/Django API. I used Django REST framework to serve my data from a Postgres database and a seperate front-end built in React. The challenges with this product included multiple relationships on the backend as well as CRUD functionality for the various models. The project also gave me exposure to user authentication and various forms needed from registering a user to logging in. Currently i'm, looking to improve the site, fix a number of existing bugs and will be redeploying shortly`,
  logos: ['fab fa-python fa-3x m-2', 'fab fa-react fa-3x m-2', 'fab fa-js-square fa-3x m-2', 'fab fa-html5 fa-3x m-2','fab fa-css3-alt fa-3x m-2' ],
  sourceCode: 'https://github.com/ashleynathansmith/sei-project-4',
}
]

export default projectData