import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function Nav() {
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = React.useState(false)

  const handleMenuToggle = () => {
    setIsOpen(!isOpen)
  }

  React.useEffect(() => {
    setIsOpen(false)
  }, [pathname])

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="container">
      <div className="navbar-brand">
          <Link to="/" className="home-logo navbar-item is-family-primary has-text-weight-light is-uppercase is-size-5 has-text-dark"><strong>home</strong></Link>
          <span onClick={handleMenuToggle} className={`navbar-burger ${isOpen ? 'is-active' : ''}`}>
          <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
          </span>
      </div>
      <div className={`navbar-menu ${isOpen ? 'is-active' : ''}`}>
      <Link to="/about" className="navbar-item is-family-primary has-text-weight-light is-size-5">About Me</Link>
        <Link to="/projects" className="navbar-item is-family-primary has-text-weight-light is-size-5">Projects</Link>
        <a href="https://github.com/ashleynathansmith" className="navbar-item is-family-primary has-text-weight-light is-size-5">Github</a>
        <a className="mailto navbar-item is-family-primary has-text-weight-light is-size-5" href="mailto:ashleynathansmith@gmail.com">Contact Me</a>
      </div>
      </div>
    </nav>
  )
}

export default Nav