import React from 'react'
import { Link } from 'react-router-dom'

import projectData from '../../lib/projectData'

function Projects() {
  const [isActive, setIsActive] = React.useState(null)

  const showMe = id => {
    setIsActive(id)
  }

  return (

    <section className="hero is-fullheight has-background-light 
      animate__animated 
      animate__fadeIn">
      <div className="hero-body">
        <div className="container">
          <div className="title-wrapper p-5">
          <h1 className="title has-text-centered is-family-primary 
            has-text-weight-light is-1 has-text-dark project-title
            animate__animated 
            animate__fadeInRight"
          >
            Projects
            </h1>
          </div>
          <div className="columns is-multiline is-gapless
          is-8-mobile is-offset--mobile
          is-10-tablet is-offset-1-tablet
          is-5-desktop is-offset-1-desktop
          is-5-widescreen is-offset-1-widescreen
          is-5-fullhd is-offset-1-fullhd"
          >
            {projectData && projectData.map(project => (
              <div
                key={project.title}
                className='project-columns column is-one-third mt-5 animate__animated animate__fadeInUp'
                onMouseEnter={() => showMe(project.id)}
                onMouseLeave={() => setIsActive(null)}
              >
                {isActive === project.id ?
                  <div className="has-text-centered mt-5 
                  animate__animated animate__fadeIn">
                    <h2 className="title is-family-primary has-text-weight-light 
                  animate__animated animate__fadeInDown"
                    >
                      {project.title}
                    </h2>
                    <h3 className="subtitle is-family-primary has-text-weight-light name
                  animate__animated animate__fadeInDown"
                    >
                      {project.technologies}
                    </h3>
                    <Link className="button mt-5 is-dark is-outlined is-family-primary has-text-weight-light 
                  animate__animated animate__fadeInUp"
                      to={`/projects/${project.id}`}
                    >
                      Learn more</Link>
                  </div>
                  :
                  <div>
                    <figure>
                      <img src={project.image} alt={project.name} />
                    </figure>
                  </div>
                }
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Projects